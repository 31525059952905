<template>
  <div>
    <slot name="header" />
    <div class="pb-12">
      <div class="container mx-auto pb-12 pt-12 max-w-md">
        <automated-advise-view
          :data="this.advisory"
          :checkout-submitting="submitting"
          @doctorCommentUpdated="setDoctorComment"
          @checkoutActionClicked="checkout()"
          @doctorCommentActionClicked="next()"
          :options="data.viewOptions" />
        <div
          class="max-w-sm mt-6 mx-auto"
          v-if="data.viewOptions.checkoutEnabled && this.advisory && data.viewOptions.manualCheckoutEnabled && canPerscribe"
        >
          <div v-if="!this.checkoutComplete">
            <default-button
              @click.prevent.native="checkout()"
              primary
              :loading="submitting"    
              class="w-full"
            >
              {{ data.viewOptions.checkoutButtonText }}
            </default-button>
          </div>
          <transition
            enter-active-class="transition ease-out duration-100"
            leave-active-class="transition ease-in duration-75"
            enter-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
            appear
          >
            <div
              class="max-w-sm mt-6 mx-auto text-xl text-center"
              v-if="this.checkoutComplete"
            >
              <span> {{ data.viewOptions.checkoutReceipt }} </span>
            </div>
          </transition>
        </div>
        <div v-if="data.viewOptions.checkoutEnabled && data.viewOptions.automaticCheckoutEnabled && this.advisory && this.checkoutComplete">
          <div
            class="max-w-sm mt-6 mx-auto text-l text-center"
            v-if="this.checkoutComplete"
          >
            <span> {{ data.viewOptions.checkoutReceipt }} </span>
          </div>
        </div>
      </div>
    </div>
    <slot
      :is-hidden="!this.canGoNext"
      :is-valid="true" />
  </div>
</template>
<script>
import axios from "axios"
import AutomatedAdviseView from "../AutomatedAdviseView.vue"
export default {
  components: {
    AutomatedAdviseView
  },  
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    },
    slideFormData: {
      type: Array,
      default() {
        return []
      }
    },
    next: {
      type: Function,
      default () {
        return () => {}
      }
    },
    setFormData: {
      type: Function,
      default () {
        return () => {}
      }
    },
    submit: {
      type: Function,
      default () {
        return () => {}
      }
    },
    submitting: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      model: {
        doctorComment: null
      },
      complete: false,
      bufferComplete: false,
      fetchAttempts: 0,
      advised: false,
      advisory: null,
      checkoutComplete: false
    }
  },

  methods: {
    fetchData() {
      axios.post("/api/aav2/calculate", { slideData: this.answeredQuestions, calculatorIdentifer: this.data.calculator })
        .then(({ data }) => {
          this.updateFromResponseData(data)
        })
        .catch(error => {
          if (error.response && error.response.status !== 200 && this.fetchAttempts < 5) {
            this.fetchAttempts++
            setTimeout(() => this.fetchData(), 1000 * this.fetchAttempts)
          }

          if (error.response && error.response.status !== 200 && this.fetchAttempts === 5) {
            this.advised = true
          }
        })
    },

    updateFromResponseData(data) {
      this.advisory = data
      this.advised = true
      this.setFormData("automatedadvice", this.advisory)
      if(this.data.viewOptions.checkoutEnabled && this.data.viewOptions.automaticCheckoutEnabled)
      {
        this.checkout()
      }
    },

    checkout()
    {
      this.checkoutComplete = false
      this.submit(false)
      this.checkoutComplete = true
    },

    setDoctorComment(value)
    {
      this.model.doctorComment = value
    }
  },

  created() {
    setTimeout(() => {
      this.bufferComplete = true
    }, 5000)
    this.fetchData()
  },


  computed: {
    answeredQuestions() {
      return this.slideFormData.filter(x => x.type !== "automaticAdvisorSlide" 
      && x.type !== "orderSummarySlide" && x.value !==null && x.type !== "aav2")
    },
    canPerscribe() {
      if (!this.advisory) {
        return false
      }


      let isValidOutput = this.advisory?.outputs?.find(o => o.type === "CanPerscribe")
      if (isValidOutput.value === "IKKE FERDIG UTFYLT") {
        return false
      }

      const first = this.primary?.value
      if (!first || first === "Ingenting") {
        return false
      }
      return true
    },
    primary() {
      return this.advisory?.outputs?.find(e => e.type == "Recommendation" && e.name == "Recommendation 1" && (e.calculationFor == "PATIENT" || e.calculationFor === "ALL"))
    },
    canGoNext()
    {
      if(!this.canPerscribe)
      {
        return false
      }
      if(this.data.viewOptions.checkoutEnabled)
      {
        return false
      }
      if(this.data.viewOptions.doctorCommentEnabled)
      {
        return false
      }
      return true
    }
  }
}
</script>
